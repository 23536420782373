<template>
  <div class="expande-horizontal expande-vh-vertical centraliza">
    <v-flex xs12 md6>
      <div class="expande-horizontal wrap">
        <v-flex
          v-if="step < 3"
          class="animate__animated"
          :class="personalizedClass"
          xs12
        >
          <div class="expande-horizontal column centraliza">
            <img height="50" src="img/logo_mini.png" />
            <span
              class="fonte white--text"
              style="font-size: 16pt; font-weight: 300; line-height: 1.5;"
              >Bem vindo!</span
            >
            <span
              class="fonte mb-3 white--text"
              style="font-size: 12pt; font-weight: 300; line-height: 1.5;"
            >
              Vamos configurar sua senha e loja agora!
            </span>
          </div>
        </v-flex>
        <v-flex class="animate__animated fonte" :class="personalizedClass" xs12>
          <div class="expande-horizontal column">
            <v-stepper v-model="step" flat alt-labels>
              <v-stepper-header
                class="no-shadow"
                flat
                v-if="step < 3"
                :color="$theme.primary"
              >
                <v-stepper-step
                  step="1"
                  :color="$theme.primary"
                  :complete="step > 1"
                >
                  Senha
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  step="2"
                  :color="$theme.primary"
                  :complete="step > 2"
                >
                  Info da Empresa
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :color="$theme.primary"
                  :complete="step === 3"
                  step="3"
                >
                  Conclusão
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items flat>
                <v-stepper-content :key="`1-content`" step="1">
                  <div class="expande-horizontal centraliza wrap">
                    <v-flex class="mt-3" xs12>
                      <div class="expande-horizontal wrap">
                        <v-flex xs12>
                          <v-form ref="criarSenha">
                            <v-flex xs12>
                              <div class="expande-horizontal wrap">
                                <v-flex xs12>
                                  <v-text-field
                                    v-model="payload.password"
                                    :rules="[v => !!v || 'Preencha este campo']"
                                    :color="$theme.primary"
                                    label="Informe a senha a ser usada"
                                    :type="showPass ? 'text' : 'password'"
                                    @click:append="changePassVisibility"
                                    filled
                                    :append-icon="
                                      showPass ? 'mdi-eye-off' : 'mdi-eye'
                                    "
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                  <v-text-field
                                    v-model="payload.confirmPassword"
                                    :rules="[v => !!v || 'Preencha este campo']"
                                    :color="$theme.primary"
                                    label="Informe novamente a senha"
                                    filled
                                    @click:append="changePassVisibility"
                                    :type="showPass ? 'text' : 'password'"
                                    :append-icon="
                                      showPass ? 'mdi-eye-off' : 'mdi-eye'
                                    "
                                  ></v-text-field>
                                </v-flex>
                              </div>
                            </v-flex>
                          </v-form>
                        </v-flex>
                      </div>
                    </v-flex>
                  </div>
                </v-stepper-content>
                <v-stepper-content :key="`2-content`" step="2">
                  <div class="expande-horizontal centraliza wrap">
                    <v-flex class="pb-3" v-if="false" xs12>
                      <div class="expande-horizontal centraliza face-img">
                        <div
                          class="input-wrapper"
                          style="display: flex; height: 140px; align-items: center;"
                        >
                          <label for="input-file">
                            <span
                              class="fonte-subtitulo fonte-italica text-center grey--text mr-1"
                            >
                              <img
                                width="80"
                                height="100"
                                src="img/config/local-avatar.svg"
                                alt=""
                              />
                            </span>
                            <v-icon color="grey">mdi-image-plus-</v-icon>
                          </label>
                          <input
                            style="cursor: pointer;"
                            id="input-file"
                            type="file"
                            accept="image/*"
                            name="importxml"
                            @change="upload"
                          />
                          <span id="file-name"></span>
                        </div>
                      </div>
                    </v-flex>
                    <v-flex class="mt-3" xs12>
                      <div class="expande-horizontal wrap">
                        <v-flex xs12>
                          <v-form ref="criarEmpresa">
                            <v-flex xs12>
                              <div class="expande-horizontal wrap">
                                <v-flex xs12>
                                  <v-text-field
                                    :color="$theme.primary"
                                    dense
                                    v-model="payload.name_market"
                                    :rules="[v => !!v || 'Preencha este campo']"
                                    label="Nome da empresa"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                  <v-text-field
                                    :color="$theme.primary"
                                    dense
                                    label="CNPJ"
                                    :rules="[v => !!v || 'Preencha este campo']"
                                    v-model="payload.cnpj_market"
                                    v-mask="['##.###.###/####-##']"
                                  ></v-text-field>
                                </v-flex>
                                <!-- <v-flex xs12>
                                  <v-text-field
                                    :color="$theme.primary"
                                    dense
                                    :rules="[v => !!v || 'Preencha este campo']"
                                    v-mask="['XXXXXXXXXXXXXXXXXXXXXX']"
                                    v-model="payload.link_market"
                                    label="Link da sua loja"
                                    suffix=".menurocket.com.br"
                                    hint="Apenas letras e números são permitidos"
                                  ></v-text-field>
                                </v-flex> -->
                              </div>
                            </v-flex>
                          </v-form>
                        </v-flex>
                      </div>
                    </v-flex>
                  </div>
                </v-stepper-content>
                <v-stepper-content :key="`3-content`" step="3">
                  <div
                    class="expande-horizontal fonte column centraliza wrap pt-12 pb-12"
                  >
                    <!-- <v-icon class="mb-6" color="green" size="70"
                      >mdi-check-circle-outline</v-icon
                    > -->
                    <img
                      src="img/logo_mini.png"
                      class="mb-6"
                      height="60"
                      alt="logo"
                    />
                    <h2 class="text-center mb-3">Empresa configurada</h2>

                    <span style="max-width: 350px;" class=" mb-4 text-center">
                      Você pode ver e editar mais configurações como endereço,
                      telefone, entre outros. Basta acessar o menu de
                      Configuração.
                    </span>

                    <v-btn
                      @click="$router.push('/dashboard')"
                      :color="$theme.primary"
                      class="white--text"
                    >
                      Acessar
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </div>
        </v-flex>
        <!-- <v-flex xs12 class="pa-3 pt-6 pb-6 animate__animated animate__fadeInUp">
        </v-flex> -->
        <!-- BOTÃO PARA ACESSAR DE FATO -->
        <v-flex
          v-if="step < 3"
          xs12
          class="animate__animated"
          :class="personalizedClass"
        >
          <div class="expande-horizontal pt-3 centraliza">
            <v-btn @click="nextPass" :color="$theme.primary" rounded dark>
              <span
                class="fonte-boas-vindas-subtitle black--text font-weight-light text-capitalize"
              >
                Avançar
              </span>
              <v-icon size="21" color="">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </v-flex>
      </div>
    </v-flex>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      personalizedClass: "animate__fadeInUp",
      personalizedClass2: "animate__fadeInUp",
      selectedIndex: false,
      indexIsSelected: false,
      empresas: [],
      step: 1,
      showPass: false,
      payload: {
        password: "",
        confirmPassword: "",
        cnpj_market: "",
        link_market: "",
        name_market: "",
        token: this.$route.query.token || "",
        photo_perfil: "",
        photo_capa: ""
      }
    };
  },
  computed: {
    passInputIsOk() {
      return this.payload.password === this.payload.confirmPassword;
    }
  },
  methods: {
    ...mapActions([
      "logout",
      "createGlobalMessage",
      "create_market_with_token"
    ]),
    animeIn() {},
    changePassVisibility() {
      this.showPass = this.showPass ? false : true;
      this.$forceUpdate();
    },
    upload() {},
    async nextPass() {
      if (this.step === 1 && this.passInputIsOk) {
        if (this.$refs.criarSenha.validate()) {
          this.step = 2;
        } else {
          this.createGlobalMessage({
            message: "As senhas não correspondem",
            timeout: 3000,
            type: "info"
          });
        }
      }

      if (this.step === 2) {
        if (this.$refs.criarEmpresa.validate()) {
          try {
            const res = await this.create_market_with_token(this.payload);
            if (res) {
              this.step = 3;
            }
          } catch (error) {
            console.log("error create market with token", error);
          }
        }
      }
    },
    animeOut() {
      this.personalizedClass = "animate__fadeOut";
      this.personalizedClass2 = "animate__pulse animate__infinite";
    },
    selecionarEmpresa(item, index) {
      this.empresas.push(item);
      this.selectedIndex = index;
      this.indexIsSelected = true;
      this.animeOut();
      setTimeout(() => {
        this.personalizedClass2 = "animate__pulse animate__zoomOut";
      }, 1000);
      setTimeout(() => {
        this.$router.push("/dashboard");
      }, 2000);
    }
  }
};
</script>

<style scoped>
.item-list {
  border-radius: 6px;
  background: #fdfcfc;
}
input[type="file"] {
  display: none;
}

.face-img {
  background: #2193b0; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #6dd5ed,
    #2ecaf1
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #6dd5ed,
    #2cc4ea
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  height: 150px;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
}

.no-shadow {
  box-shadow: none;
}

.input-wrapper label {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background: linear-gradient(-45deg, #eceef1, #dedee2);
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  margin: 8px;
  padding: 6px 20px;
}
</style>
